<template>
  <div class="customer">
    <!-- 功能区 -->
    <el-card class="btnCard">
      <el-form :model="customerData" label-width="70px" ref="form" inline>
        <el-form-item label="手机号码" prop="phoneNum">
          <el-input
            placeholder="请输入手机号码"
            v-model="customerData.phoneNum"
          ></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="customerName">
          <el-input
            placeholder="请输入姓名"
            v-model="customerData.customerName"
          ></el-input>
        </el-form-item>
        <el-form-item label="处理状态" prop="status">
          <el-select v-model="customerData.status" placeholder="请选择处理状态">
            <el-option label="未申请" value="1"></el-option>
            <el-option label="未处理" value="2"></el-option>
            <el-option label="已处理" value="3"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="getTableList"
            >查询</el-button
          >
          <el-button plain @click="clear">清空</el-button>
          <!-- <el-button plain @click="moreSearch">更多查询条件</el-button> -->
        </el-form-item>
      </el-form>
    </el-card>
    <!-- 表格区 -->
    <el-card class="tableCard">
      <el-button class="datas" type="primary" @click="exportCustomerInfo"
        >数据导出</el-button
      >
      <TableCompent
        :tableHeader="tableHeader"
        :tableData="tableData"
        :pagination="pagination"
        @getList="getTableList"
      >
        <template v-slot:operation>
          <el-table-column fixed="right" label="操作" width="">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="dispose(scope.row)"
                v-show="scope.row.status === 2"
                >处理</el-button
              >
              <div v-show="scope.row.status !== 2">--</div>
            </template>
          </el-table-column>
        </template>
      </TableCompent>
    </el-card>
  </div>
</template>

<script>
import TableCompent from '@/components/tableCompent'
// import axios from 'axios'
import {
  queryCustomerList,
  handleDataReq,
  exportCustomerInfo
} from '@/api/customer.js'
export default {
  components: {
    TableCompent
  },
  data () {
    return {
      customerData: {
        phoneNum: '',
        customerName: '',
        status: ''
      },
      tableHeader: [
        {
          prop: 'phoneNum',
          label: '手机号',
          width: '120'
        },
        {
          prop: 'customerName',
          label: '姓名',
          width: '100'
        },
        {
          prop: 'region',
          label: '所在地区',
          width: '250'
        },
        {
          prop: 'hospitalName',
          label: '医院',
          width: '250'
        },
        {
          prop: 'departmentName',
          label: '科室',
          width: '150'
        },
        {
          prop: 'mailbox',
          label: '个人邮箱',
          width: '200'
        },
        {
          prop: 'needsFile',
          label: '资料需求',
          width: '150'
        },
        {
          prop: 'status',
          label: '处理状态',
          //   width: '100',
          status: true,
          // color: false,
          statusList: ['', '未申请', '未处理', '已处理'],
          width: ''
        },
        {
          prop: 'registerTime',
          label: '注册时间',
          width: '200'
        },
        {
          prop: 'applyTime',
          label: '申请时间',
          width: '200'
        }
        // {
        //   prop: 'status',
        //   label: '状态',
        //   status: true,
        //   // color: false,
        //   statusList: ['禁用', '正常'],
        //   width: ''
        // },
      ],
      tableData: [
        // {
        //   phone: '13415643936',
        //   alarmLevel: '袁观林',
        //   deviceModel: '安宏基天曜六楼安保感控',
        //   propertyName: '深圳市龙华区人民医院',
        //   relation: '7758258课室',
        //   triggerValues: '1246059721@qq.com',
        //   triggerValuess: '所有资料',
        //   triggerValuesss: '已处理',
        //   triggerValuexs: '2021-11-11',
        //   triggerValuexss: '2021-11-11'
        // }
      ],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        totalCount: 10
      }
    }
  },
  created () {
    this.getTableList()
  },
  methods: {
    //   获取列表数据
    getTableList () {
      const page = {
        curr: this.pagination.currentPage,
        size: this.pagination.pageSize
      }
      queryCustomerList({ ...this.customerData, ...page }).then(res => {
        this.pagination.currentPage = res.data.data.currPage
        this.pagination.pageSize = res.data.data.pageSize
        this.pagination.totalCount = res.data.data.totalCount
        this.tableData = res.data.data.list.map(ele => {
          ele.region = ele.province + ele.city + ele.area || ''
          return ele
        })
      })
    },
    // 处理
    dispose (e) {
      console.log(e)
      handleDataReq(e.openId).then(res => {
        this.$message.success('处理成功!')
        this.getTableList()
      })
    },
    // 清空搜索
    clear () {
      this.customerData = {
        phoneNum: '',
        customerName: '',
        status: ''
      }
      this.getTableList()
    },
    // 导出数据
    exportCustomerInfo () {
      //   window.location.href =
      //     process.env.VUE_APP_URL +
      //     `/admin/exportCustomerInfo?phoneNum=${this.customerData.phoneNum}&customerName=${this.customerData.customerName}&status=${this.customerData.status}`
      exportCustomerInfo(this.customerData)
        .then(response => {
          console.log(response)
          download(response, response.data)
        })
        .catch(() => {
          window.location.href =
            process.env.VUE_APP_URL +
            `/admin/exportCustomerInfo?phoneNum=${this.customerData.phoneNum}&customerName=${this.customerData.customerName}&status=${this.customerData.status}`
        })

      function download (response, data) {
        if (!data) {
          return
        }
        var date = new Date()
        var time = date.toLocaleDateString().replace(/\//g, '-')
        const _fileName = '客户信息_' + time + '.xls'
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', 'excel.xlsx')
        link.download = _fileName
        document.body.appendChild(link)
        link.click()
      }

      //   this.$axios
      //     .get('/admin/exportCustomerInfo', {
      //       params: this.customerData
      //     })
      //     .then(res => {
      //       console.log(res)
      //     })
      // 导出
      //   exportHandle () {
      //   var params = qs.stringify({
      //     token: Cookies.get('token'),
      //     status: 0,
      //     ...this.dataForm
      //   })
      //   window.location.href = this.$http.adornUrl(
      //     `recruit/recruitapplicationrecord/export2?${params}`
      //   )
      //   },
    }
  }
}
</script>

<style lang="less" scoped>
.customer {
  height: 100%;
  overflow: auto;
  //   color: antiquewhite;
  background: #f8faff;
  .btnCard {
    /deep/.el-input__inner {
      width: 240px;
      height: 35px;
    }
    /deep/.el-form-item {
      margin-bottom: 0;
    }
    /deep/ .el-form-item__content {
      line-height: 35px;
    }
    /deep/ .el-button {
      padding: 8px 20px;
    }
    margin-bottom: 20px;
  }
  .tableCard {
    // /deep/ .el-button {
    //   padding: 8px 20px;
    // }
    .datas {
      padding: 8px 20px;
    }
  }
}
</style>
