var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tablePage"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData}},[_vm._t("details"),_c('el-table-column',{attrs:{"type":"index","index":_vm.indexMethod,"label":"序列","width":"100"}}),_vm._l((_vm.tableHeader),function(item,index){return _c('el-table-column',{key:index,attrs:{"prop":item.prop,"label":item.label,"width":item.width},scopedSlots:_vm._u([(item.status !== undefined)?{key:"default",fn:function(scope){return [_c('div',{staticClass:"status"},[(item.color)?_c('div',{class:scope.row[item.prop] === 0 ? 'stus' : 'stus2'}):_vm._e(),_c('div',{class:item.bgcolor
                ? scope.row[item.prop] === 0
                  ? 'green'
                  : scope.row[item.prop] === 1 ||
                    scope.row[item.prop] === null
                  ? 'red'
                  : ''
                : ''},[_vm._v(" "+_vm._s(scope.row[item.prop] === null ? '离线' : item.statusList[scope.row[item.prop]])+" ")])])]}}:null],null,true)})}),_vm._t("operation")],2),_c('div',{staticClass:"footpage"},[_c('el-pagination',{attrs:{"current-page":_vm.pagination.currentPage,"page-sizes":[10, 20, 30, 40, 50, 100],"page-size":_vm.pagination.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.pagination.totalCount},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }