<template>
  <div class="tablePage">
    <!-- :height="tableHeight" -->
    <el-table :data="tableData" style="width: 100%">
      <slot name="details"></slot>
      <el-table-column
        type="index"
        :index="indexMethod"
        label="序列"
        width="100"
      >
      </el-table-column>
      <el-table-column
        v-for="(item, index) in tableHeader"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        :key="index"
      >
        <template v-slot="scope" v-if="item.status !== undefined">
          <div class="status">
            <div
              v-if="item.color"
              :class="scope.row[item.prop] === 0 ? 'stus' : 'stus2'"
            ></div>
            <div
              :class="
                item.bgcolor
                  ? scope.row[item.prop] === 0
                    ? 'green'
                    : scope.row[item.prop] === 1 ||
                      scope.row[item.prop] === null
                    ? 'red'
                    : ''
                  : ''
              "
            >
              {{
                scope.row[item.prop] === null
                  ? '离线'
                  : item.statusList[scope.row[item.prop]]
              }}
            </div>
          </div>
        </template>
      </el-table-column>
      <slot name="operation"></slot>
    </el-table>
    <div class="footpage">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="pagination.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.totalCount"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: ['tableHeader', 'tableData', 'tableHeight', 'pagination'],
  data () {
    return {
      // pagination: {
      //   currentPage: 1,
      //   pageSize: 10,
      //   totalCount: 100
      // }
    }
  },
  methods: {
    click () {
      console.log(this.pagination)
      // console.log(row, item)
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
      this.pagination.pageSize = val
      this.$emit('getList', this.pagination)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.pagination.currentPage = val
      this.$emit('getList', this.pagination)
    },
    indexMethod (index) {
      return (
        index + 1 + (this.pagination.currentPage - 1) * this.pagination.pageSize
      )
    }
  }
}
</script>

<style lang="less" scoped>
/* .tablePage {
  margin-bottom: 20px;
} */

.tablePage {
  width: 100%;
  height: 100%;
  overflow: hidden;
  // /deep/ .el-pagination__editor.el-input {
  //   width: 50px;
  // }
  // /deep/.el-table thead {
  //   color: #303133;
  //   font-weight: 500;
  // }
  // /deep/.el-button span {
  //   font-size: 14px;
  // }
  /deep/.el-table th > .cell {
    font-size: 14px;
  }
  /deep/.el-table .cell {
    font-size: 14px;
    min-height: 32px;
    line-height: 32px;
  }
  // /deep/.el-table th {
  //   background-color: #f8faff;
  //   // padding: 12px 0;
  // }
  /deep/.el-table td,
  /deep/.el-table th {
    padding: 8px 0;
  }
  .status {
    display: flex;
    align-items: center;
    font-size: 14px;
    .stus {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #f8544b;
      margin-right: 8px;
    }
    .stus2 {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #3ad2bc;
      margin-right: 8px;
    }
    .green {
      box-sizing: border-box;
      max-height: 32px;
      padding: 0px 10px;
      color: #67c23a;
      background-color: #f0f9ec;
      border: 1px solid #e1f3d8;
      border-radius: 2px;
    }
    .red {
      box-sizing: border-box;
      max-height: 32px;
      padding: 0px 10px;
      color: #f56c6c;
      background-color: #fef1f1;
      border: 1px solid #fde2e2;
      border-radius: 2px;
    }
  }
  .footpage {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
</style>
